<template>
  <div class="activity statistic-wrapper">
    <div class="content">
      <d-search class="d-search"
                :searchData="searchData"
                @searchEvent="searchEvent"
                @exportEvent="exportEvent" />

      <d-lable :columnData="columnData"
               :tableData="tableData"></d-lable>
      <d-paging :pager="pager"
                style="marginTop:50px;"
                @change="getItemList" />
    </div>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DLable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import { onDownload } from "@/utils/utils";
import {getShopCode} from "../../../utils/utils";
export default {
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      searchData: {
        searchItem: [
          {
            label: "活动名称",
            value: "name",
            type: "commonInput",
            placeholder: "请输入活动名称"
          },
          {
            label: '活动类型',
            value: 'type',
            type: 'commonInput',
            placeholder: "请输入活动类型"
          },
          {
            label: '选择店铺',
            value: 'shopIdList',
            type: 'linkageSelect',
            dateType: 'month',
            placeholder: '',
            options: [],
            optionProps: {
              value: 'name',
              label: 'name',
              children: 'childList'
            }
          }, {
            label: '状态',
            value: 'status',
            type: 'commonSelect',
            placeholder: '',
            options: [
              {
                label: '未核验',
                value: 0,
              },
              {
                label: '已核验',
                value: 1
              },
              {
                label: '已过期',
                value: 2
              }
            ],
          }
        ],
        searchBtn: {
          queryBtn: true,
          exportBtn: true,
        },
        params: {
          status: null
        }
      },
      columnData: [
        { prop: "activityManagerName", label: "活动名称" },
        { prop: "contentType", label: "活动类型" },
        { prop: "content", label: "活动内容" },
        { prop: "addr", label: "活动位置" },
        { prop: "floorName", label: "楼层" },
        { prop: "shopName", label: "店铺" },
        { prop: "shopCode", label: "店铺号" },
        { prop: "username", label: "提交人" },
        { prop: "checkerName", label: "核验人" },
        { prop: "checkTime", label: "核验时间" },
        { prop: "statusName", label: "状态" },
      ],
      tableData: []
    };
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.init();
  },
  methods: {
    /**
     * 搜索按钮点击
     */
    searchEvent () {
      this.getItemList();
    },
    /**
     * 导出
     */
    exportEvent () {
      const obj = this.getParams();
      this.$api.mentapi.exportActivityApply(Object.assign({},obj,{pageNum:1,pageSize:10000})).then(res => {
        let { headers, data } = res;
        onDownload(headers, data);
      });
    },

    getParams(){
      return {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        activityManagerName: this.searchData.params.name,
        contentType: this.searchData.params.type,
        shopCode: getShopCode(this.searchData.params.shopIdList),
        status: this.searchData.params.status
      }
    },
    /**
     * 查询请求
     */
    getItemList () {
      const obj = this.getParams();
      this.$api.mentapi.getActivityApplyList(obj)
          .then(({code,data}) => {
            if (code === 200) {
              this.pager.count = data.total
              this.tableData = data.list
              this.tableData = this.tableData.map(el=>{el.statusName = ["未核验","已核验","已过期"][el.status];return el})
            }
          })
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
          .then(({ data, code }) => {
            if (code == 200) {
              this.childList = data.childList
              const child = [{
                id: data.id,
                name: data.name,
                childList: data.childList
              }]
              this.searchData.searchItem.forEach(ele => {
                if (ele.type === 'linkageSelect') {
                  ele.options = child
                }
              })
            }
          })
    },
    /**
     * init 初始化
     */
    init () {
      this.getShopList();
      this.getItemList()
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../style/statistic";
</style>
